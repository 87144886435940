<template>
  <div class="contanier">
    <b-card>
      <h3>{{ $t("items.edit") }}</h3>
      <hr />
      <Item-Form></Item-Form>
    </b-card>
  </div>
</template>

<script>
import ItemForm from "@/components/ItemForm.vue";

export default {
  name: "itemnew",
  components: {
    ItemForm
  }
};
</script>
